import React, { useCallback } from "react";
import Button, { ButtonProps } from "@mui/material/Button";

import { SERVER } from "../../../config/utils";

const GoogleOAuthBase: React.FC<{
  buttonsProps: Omit<ButtonProps, "onClick">;
  redirectUrl: string;
}> = ({ buttonsProps, redirectUrl }) => {
  const handleGoogleOAuth2 = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: process.env.NEXT_PUBLIC_GOOGLE_OAUTH2_CLIENT_ID,
      redirect_uri: `${SERVER}/api/auth/google/`,
      prompt: "select_account",
      access_type: "offline",
      scope,
      state: redirectUrl,
    };

    // @ts-ignore
    const urlParams = new URLSearchParams(params).toString();

    window.location.href = `${googleAuthUrl}?${urlParams}`;
  }, [redirectUrl]);

  return <Button onClick={handleGoogleOAuth2} {...buttonsProps} />;
};

export default GoogleOAuthBase;
