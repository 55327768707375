import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";

import { USER_LOGIN_URL } from "../../../../common/utils/constants";
import { neutral } from "../../../../common/config/colors";

const FormCaption: React.FC<{ linkText?: string }> = ({
  linkText = "Login to Enroll",
}) => {
  const router = useRouter();

  return (
    <Typography align="center" variant="body2" fontWeight={500}>
      Have AI Planet account?{" "}
      <Link href={`${USER_LOGIN_URL}?next=${router.asPath}`} passHref>
        <a style={{ textDecoration: "none" }}>
          <Typography
            align="center"
            component="span"
            variant="body2"
            fontWeight={600}
            color={neutral["900"]}
          >
            {linkText}
          </Typography>
        </a>
      </Link>
    </Typography>
  );
};

export default FormCaption;
