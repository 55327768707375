import React from "react";
import { useRouter } from "next/router";
import { Formik, Form } from "formik";
import Box from "@mui/material/Box";

import SpinnerButton from "@packages/common/src/components/form/SpinnerButton";

import GoogleOAuthButton from "../../../common/components/form/auth/GoogleOAuthButton";
import OrSeparator from "../../../common/components/layout/utils/OrSeparator";
import useSignUp from "../../../accounts/components/signup/useSignUp";
import RenderFormFields from "../../../accounts/components/common/RenderFormFields";
import FormCaption from "../../../bootcamps/components/detail/enroll-section/FormCaption";
import { enrollButtonStyles } from "./../../../bootcamps/components/detail/common/EnrollButton";
import { FRONTEND_LEARNING_PATHS_URL } from "../../../common/utils/constants";
import { neutral } from "../../../common/config/colors";

const SignUpForm: React.FC<{ redirect_url?: string }> = ({
  redirect_url = FRONTEND_LEARNING_PATHS_URL,
}) => {
  const router = useRouter();

  const { initialState, handleSubmit, signupFormFields, validationSchema } =
    useSignUp(redirect_url);

  return (
    <Box
      width={{ xs: "90vw", sm: 432 }}
      py={10}
      bgcolor={neutral["A500"]}
      borderRadius={{ xs: 3.5, md: 7.5 }}
      sx={{ display: "grid", placeItems: "center" }}
    >
      <GoogleOAuthButton redirectUrl={router.asPath}>
        Sign Up With Google
      </GoogleOAuthButton>
      <OrSeparator />
      <Box width={{ xs: "85%", md: "80%" }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <RenderFormFields fields={signupFormFields} />
              <Box sx={{ display: "grid", placeItems: "center", py: 6 }}>
                <SpinnerButton
                  type="submit"
                  disabled={isSubmitting}
                  sx={{ ...enrollButtonStyles }}
                >
                  Start Learning Now
                </SpinnerButton>
              </Box>
              <FormCaption linkText="Log In" />
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default SignUpForm;
