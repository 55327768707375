import React from "react";

export const GoogleDesktopIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8973 9.62294H20.1253V9.58317H11.5003V13.4165H16.9163C16.1262 15.648 14.003 17.2498 11.5003 17.2498C8.32489 17.2498 5.75033 14.6753 5.75033 11.4998C5.75033 8.3244 8.32489 5.74984 11.5003 5.74984C12.9661 5.74984 14.2996 6.3028 15.315 7.20602L18.0256 4.49538C16.314 2.90023 14.0246 1.9165 11.5003 1.9165C6.20793 1.9165 1.91699 6.20744 1.91699 11.4998C1.91699 16.7922 6.20793 21.0832 11.5003 21.0832C16.7927 21.0832 21.0837 16.7922 21.0837 11.4998C21.0837 10.8573 21.0175 10.23 20.8973 9.62294Z"
        fill="#FFC107"
      />
      <path
        d="M3.02148 7.03928L6.17009 9.34838C7.02205 7.23909 9.08534 5.74984 11.4999 5.74984C12.9656 5.74984 14.2991 6.3028 15.3145 7.20603L18.0251 4.49538C16.3136 2.90023 14.0241 1.9165 11.4999 1.9165C7.8189 1.9165 4.62669 3.99465 3.02148 7.03928Z"
        fill="#FF3D00"
      />
      <path
        d="M11.5003 21.0832C13.9757 21.0832 16.2249 20.1359 17.9255 18.5953L14.9594 16.0855C13.9649 16.8418 12.7497 17.2508 11.5003 17.2498C9.00769 17.2498 6.89121 15.6604 6.09387 13.4424L2.96875 15.8502C4.55479 18.9538 7.77575 21.0832 11.5003 21.0832Z"
        fill="#4CAF50"
      />
      <path
        d="M20.8969 9.62327H20.125V9.5835H11.5V13.4168H16.916C16.5381 14.4789 15.8572 15.4069 14.9577 16.0863L14.9591 16.0853L17.9251 18.5952C17.7153 18.7859 21.0833 16.2918 21.0833 11.5002C21.0833 10.8576 21.0172 10.2304 20.8969 9.62327Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export const GoogleMobileIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2624 7.94968H16.6247V7.91683H9.49967V11.0835H13.9738C13.321 12.9269 11.5671 14.2502 9.49967 14.2502C6.87649 14.2502 4.74967 12.1233 4.74967 9.50016C4.74967 6.87698 6.87649 4.75016 9.49967 4.75016C10.7105 4.75016 11.8121 5.20695 12.6509 5.9531L14.8901 3.71387C13.4762 2.39614 11.5849 1.5835 9.49967 1.5835C5.1277 1.5835 1.58301 5.12818 1.58301 9.50016C1.58301 13.8721 5.1277 17.4168 9.49967 17.4168C13.8717 17.4168 17.4163 13.8721 17.4163 9.50016C17.4163 8.96935 17.3617 8.4512 17.2624 7.94968Z"
        fill="#FFC107"
      />
      <path
        d="M2.49609 5.81535L5.09711 7.72287C5.80091 5.98041 7.50536 4.75016 9.49997 4.75016C10.7108 4.75016 11.8124 5.20695 12.6512 5.9531L14.8904 3.71387C13.4765 2.39614 11.5852 1.5835 9.49997 1.5835C6.45918 1.5835 3.82214 3.30023 2.49609 5.81535Z"
        fill="#FF3D00"
      />
      <path
        d="M9.49996 17.4165C11.5448 17.4165 13.4029 16.6339 14.8077 15.3613L12.3575 13.2879C11.5359 13.9127 10.5321 14.2506 9.49996 14.2498C7.44084 14.2498 5.69244 12.9368 5.03377 11.1045L2.45215 13.0936C3.76236 15.6574 6.42315 17.4165 9.49996 17.4165Z"
        fill="#4CAF50"
      />
      <path
        d="M17.2627 7.94936H16.625V7.9165H9.5V11.0832H13.9741C13.6619 11.9605 13.0995 12.7271 12.3563 13.2884L12.3575 13.2876L14.8077 15.3609C14.6344 15.5185 17.4167 13.4582 17.4167 9.49984C17.4167 8.96902 17.362 8.45088 17.2627 7.94936Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export const MsAzureDesktopIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.3072 13.3072H0V0H13.3072V13.3072Z" fill="#F1511B" />
      <path d="M28 13.3072H14.6929V0H28V13.3072Z" fill="#80CC28" />
      <path d="M13.3069 28.0002H0V14.693H13.3069V28.0002Z" fill="#00ADEF" />
      <path d="M28 28.0002H14.6929V14.693H28V28.0002Z" fill="#FBBC09" />
    </svg>
  );
};

export const MsAzureMobileIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.55464 8.55464H0V0H8.55464V8.55464Z" fill="#F1511B" />
      <path d="M17.9999 8.55464H9.44531V0H17.9999V8.55464Z" fill="#80CC28" />
      <path d="M8.55443 17.8593H0V9.30463H8.55443V17.8593Z" fill="#00ADEF" />
      <path d="M17.9999 17.8593H9.44531V9.30463H17.9999V17.8593Z" fill="#FBBC09" />
    </svg>
  );
};
