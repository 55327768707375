import * as Yup from "yup";
import { countryCodeData } from "@packages/skillspace/src/common/utils/countryCode";
import { IFormFieldItem } from "../accountsTypes";

export let signupFormFields: IFormFieldItem[] = [
  {
    type: "email",
    name: "email",
    label: "Email",
  },
  {
    type: "text",
    name: "first_name",
    label: "First name",
  },
  {
    type: "text",
    name: "username",
    label: "Username",
  },
  {
    type: "country",
    name: "country",
    label: "Country(Optional)",
  },
  {
    type: "password",
    name: "password1",
    label: "Password",
  },
];

export const passwordValidation = () => {
  return Yup.string()
    .required("Password is required")
    .test(
      "validate-password",
      "Password must contain minimum 8 & maximum 15 characters, 1 uppercase, 1 lowercase, 1 number & 1 special case character",
      function (value) {
        if (value) {
          const regexp =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,15}$/;
          return regexp.test(value);
        }
        return false;
      }
    );
};

export const baseSignUpValidations = {
  email: Yup.string()
    .email()
    .required("email is required")
    .typeError("Please add email in correct format"),
  first_name: Yup.string()
    .required("first name is required")
    .typeError("Text type is required"),
  username: Yup.string()
    .required("User name is required")
    .typeError("Text type is required"),
  password1: passwordValidation(),
  country: Yup.string().oneOf(
    countryCodeData.map((data) => data.label),
    "Please select valid option"
  ),
};

export const validationSchema = Yup.object({
  ...baseSignUpValidations,
});
