import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";

import theme from "../../../../common/config/theme";
import { neutral, primary } from "../../../../common/config/colors";

export const enrollButtonStyles = {
  width: 190,
  backgroundColor: primary["A500"],
  borderRadius: 2.5,
  color: neutral["A500"],
  fontSize: 16,
  fontWeight: 600,
  py: { xs: 2, md: 3.25 },
};

const EnrollButton: React.FC<ButtonProps> = ({ sx, ...otherProps }) => (
  <Button
    sx={{
      ...enrollButtonStyles,
      ...(sx || {}),
    }}
    {...(otherProps || {})}
  />
);

export default EnrollButton;
