import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";

const OrSeparator: React.FC<{
  boxProps?: BoxProps;
  typographySxProps?: SxProps;
  spanSxProps?: SxProps;
}> = ({ boxProps, typographySxProps, spanSxProps }) => {
  return (
    <Box py={4} width="50%" {...(boxProps || {})}>
      <Typography
        variant="body1"
        color="textSecondary"
        component="p"
        sx={{
          display: "flex",
          "&:before, &:after": {
            content: '""',
            flex: `1 1`,
            borderBottom: "1px solid #C4C4C4",
            margin: "auto",
          },
          ...(typographySxProps || {}),
        }}
      >
        <Typography
          component="span"
          px={4}
          color="rgba(0, 0, 0, 0.5)"
          sx={spanSxProps}
        >
          or
        </Typography>
      </Typography>
    </Box>
  );
};

export default OrSeparator;
