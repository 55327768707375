import React from "react";
import { AxiosError } from "axios";
import Typography from "@mui/material/Typography";

import useDialog from "@packages/skillspace/src/common/components/dialog-provider/useDialog";

import { FRONTEND_DASHBOARD_URL } from "../../../common/utils/constants";
import { strObj } from "../accountsTypes";
import { getErrorMessage } from "../../../common/utils/error";
import { signupFormFields, validationSchema } from "./helpers";

const useSignUp = (redirectUrl = FRONTEND_DASHBOARD_URL) => {
  const { displayDialog } = useDialog();

  const initialState: strObj = {
    email: "",
    first_name: "",
    username: "",
    country: "",
    password1: "",
  };

  const handleSubmit = async (data: strObj) => {
    try {
      const { email, first_name, username, country, password1 } = data;
      const requestData: {} = {
        email,
        first_name,
        username,
        password1,
        profile: {
          country,
        },
      };

      const accountsService = new (
        await import("../../service/accountsService")
      ).default();
      await accountsService.signup({
        ...requestData,
      });
      // TODO: Once @dashboard is migrated to nextjs remove this & use router.push(/dashboard)
      window.location.href = redirectUrl;
    } catch (error) {
      displayDialog({
        title: "Error!",
        content: "",
        variant: "error",
        agreeText: "Close",
        onAgree: (closeDialog: any) => {
          closeDialog();
        },
        onDisagree: () => {},
        children: (
          <Typography variant="subtitle2">
            {getErrorMessage((error as AxiosError).response?.data)}
          </Typography>
        ),
      });
    }
  };

  return { handleSubmit, initialState, signupFormFields, validationSchema };
};

export default useSignUp;
