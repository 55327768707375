import React from "react";
import { ButtonProps } from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";

import { GoogleDesktopIcon, GoogleMobileIcon } from "./icons";
import GoogleOAuthBase from "./GoogleOAuthBase";

const GoogleOAuthButton: React.FC<
  { redirectUrl: string } & Omit<ButtonProps, "onClick">
> = ({ redirectUrl, ...props }) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <GoogleOAuthBase
      buttonsProps={{
        startIcon: isDownSm ? <GoogleMobileIcon /> : <GoogleDesktopIcon />,
        sx: {
          width: "80%",
          backgroundColor: "#fff",
          border: "1px solid #1976D2",
          borderRadius: 2,
          color: "#000",
          fontSize: 14,
          fontWeight: 500,
          py: 1,
        },
        ...props,
      }}
      redirectUrl={redirectUrl}
    />
  );
};

export default GoogleOAuthButton;
